<template>
  <router-link
    v-bind="$attrs"
    :to="to"
    :title="title"
    class="ctk-collapse-nav-item tw-flex tw-justify-between tw-items-center tw-relative tw-bg-bg-primary"
  >
    <div class="ctk-collapse-nav-item__content tw-flex tw-items-center tw-truncate">
      <span
        v-text="title"
        class="ctk-collapse-nav-item__title tw-truncate"
        data-test="title"
      />
    </div>
    <span
      v-if="hasBadge"
      :class="{
        'has-dot-icon': hasDot,
        'ctk-collapse-nav-item__badge--count': count && count > 0
      }"
      class="ctk-collapse-nav-item__badge tw-relative tw-text-center tw-px-1 tw-flex-shrink-0"
      data-test="badge"
    >
      <div
        v-b-tooltip.hover
        :title="dotTooltip"
        class="ctk-collapse-nav-item__badge__icon tw-rounded-md"
        data-test="dot"
      />
      <span
        :class="{
          'has-dot': hasDot
        }"
        data-test="badge"
        class="ctk-collapse-nav-item__badge__content tw-relative"
      >
        {{ count === null ? '-' : $n(count) }}
      </span>
    </span>
  </router-link>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - CtkCollapseNavItem
   * @param {string} [icon=null] - A CTK icon class name
   * @param {string} title
   * @param {Object} to
   * @param {boolean|null} count
   * @param {boolean} [hasDot=false] - Specify if the item should
   * @param {string} [dotTooltip=null] - A tooltip to show when hovering the dot
   * @param {boolean} [hasBadge=true] - Show / hide the badge element if necessary
   */
  export default defineComponent({
    name: 'CtkCollapseNavItem',
    inheritAttrs: false,
    props: {
      icon: {
        type: String,
        default: null
      },
      title: {
        type: String,
        required: true
      },
      to: {
        type: Object,
        required: true
      },
      count: {
        type: [Number, String],
        default: null
      },
      hasDot: {
        type: Boolean,
        default: false
      },
      dotTooltip: {
        type: String,
        default: null
      },
      hasBadge: {
        type: Boolean,
        default: true
      }
    }
  })
</script>

<style lang="scss" scoped>
.ctk-collapse-nav-item {
  height: 36px;
  color: $secondary-text;
  text-decoration: none;
}
.ctk-collapse-nav-item__title {
  line-height: 36px;
}
.ctk-collapse-nav-item__badge {
  font-size: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(117, 116, 116, var(--tw-text-opacity));
  background-color: $divider;
  min-width: 28px;
}
.ctk-collapse-nav-item__badge__icon {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 38, 28, var(--tw-bg-opacity));
  display: none;
  height: 0.5rem;
  position: absolute;
  width: 0.5rem;
  top: -0.25rem;
  right: -0.25rem;
}
.ctk-collapse-nav-item__badge--count {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 116, 116, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.ctk-collapse-nav-item__badge--count.has-dot-icon .ctk-collapse-nav-item__badge__icon {
  display: block;
}
.ctk-collapse-nav-item.router-link-active, .ctk-collapse-nav-item:hover {
  --tw-text-opacity: 1;
  color: rgba(39, 84, 145, var(--tw-text-opacity));
}
.ctk-collapse-nav-item.router-link-active .ctk-collapse-nav-item__badge, .ctk-collapse-nav-item:hover .ctk-collapse-nav-item__badge {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 84, 145, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
</style>
