<template>
  <button
    :class="{
      right,
      'ctk-side-bar-toggle--open': open
    }"
    class="ctk-side-bar-toggle tw-flex-shrink-0 tw-absolute tw-bg-bg-primary tw-flex tw-items-center close-btn-container tw-w-7 tw-h-7 tw-cursor-pointer tw-border-none tw-p-0 tw-outline-none"
    type="button"
    @click="toggle"
  >
    <svg
      width="14"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      class="ctk-side-bar-toggle__icon tw-m-auto"
      xmlns="http://www.w3.org/2000/svg"
      :class="{ 'open' : open }"
      data-test="icon"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.05729 13.5455C0.8562 13.3702 0.655618 13.1955 0.454529 13.0202L6.04602 7L0.454529 0.979753L1.05729 0.454548L7.13605 7L1.05729 13.5455ZM6.37576 13.5455C6.17468 13.3702 5.97409 13.1955 5.773 13.0202L11.3645 7L5.773 0.979753L6.37576 0.454548L12.4545 7L6.37576 13.5455Z"
        fill="#303144"
      />
    </svg>
  </button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - CtkSideBarToggle
   * @param {boolean} [open=false]
   * @param {boolean} [right=false]
   */
  export default defineComponent({
    name: 'CtkSideBarToggle',
    props: {
      open: {
        type: Boolean,
        default: false
      },
      right: {
        type: Boolean,
        default: false
      }
    },
    setup (_, { emit }) {
      function toggle () {
        emit('toggle')
      }

      return {
        toggle
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-side-bar-toggle {
    top: 1.375rem;
    left: 100%;
    transition: transform 200ms ease-in-out;
    box-shadow: 1px 1px 4px 0 rgba(164, 164, 164, 0.5);
    transform: translateX(0.25rem);

    &--open {
      transform: translateX(-0.875rem);
    }

    &__icon {
      transition: transform 200ms ease-in-out;
    }

    .open {
      transform: rotate(180deg);
    }

    &.right {
      right: 100%;
      left: inherit;
      border-right: 1px solid #F1F1F1;
      box-shadow: -2px 1px 8px rgba(0, 0, 0, 0.2);
    }
  }

</style>
