<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * Base component for list items
   * @module component - CtkItem
   */
  export default defineComponent({
    name: 'CtkItem',
    props: {
      touchable: {
        type: Boolean,
        default: true
      },
      touchableType: {
        type: String,
        required: true
      },
      isCurrent: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    render: function (h) {
      return h(this.touchableType, {
        class: {
          'ctk-item tw-bg-white tw-border-none tw-no-underline hover:tw-no-underline tw-relative tw-p-0 tw-text-normal tw-w-full': true,
          'is-active': this.isCurrent,
          'ctk-item--touchable tw-cursor-pointer': this.touchable
        },
        attrs: {
          ...this.$attrs,
          role: this.touchable && 'button',
          tabindex: this.touchable && '0'
        },
        props: this.$props
      }, this.$slots.default)
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-item {
    color: $primary-text;
    transition: box-shadow 200ms;

    &.ctk-item--touchable {
      &.is-active,
      &:active,
      &:focus,
      &.is-active:hover {
        outline: none;
        background: #E9E9EA;
      }

      &:active {
        border-color: darken($divider, 5%);
      }

      /* stylelint-disable */
      &:hover:not(:focus),
      &:active {
        background: #F9F9F9;
        color: $primary-text;
      }
      /* stylelint-enable */
    }
  }

</style>
