<template>
  <div
    id="CtkMissionsList"
    class="missions-list tw-flex tw-flex-col tw-flex-1 tw-h-full"
  >
    <div class="tw-flex tw-overflow-hidden tw-h-full">
      <missions-list-content
        v-if="getStoredMissions.length > 0 && !$wait.is('fetching missions')"
        class="md:tw-flex-shrink-0 md:tw-flex-grow-0 tw-w-full"
      />

      <div
        class="tw-overflow-y-auto tw-w-full tw-absolute md:tw-relative tw-top-0 tw-left-0"
      >
        <mission-detail
          v-if="hasSelectedMission"
          :mission="getCurrentMission"
          data-test="detail"
        />

        <mission-unselected
          v-else
          :title="$t('missions.titles.select_mission')"
          class="tw-hidden md:tw-flex tw-w-full tw-h-full"
          data-test="unselected"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import MissionsListContent from './_subs/MissionsListContent'
  import MissionUnselected from '@/views/Carriers/Missions/components/MissionUnselected/index.vue'
  import MissionDetail from '@/views/Carriers/Missions/views/_subs/MissionDetail/index.vue'

  /**
   * @module component - missionsList
   */
  export default {
    name: 'MissionsList',
    components: {
      MissionsListContent,
      MissionUnselected,
      MissionDetail
    },
    computed: {
      ...mapGetters('auth', [
        'isAccountLocked'
      ]),
      ...mapGetters('missions', [
        'getStoredMissions',
        'getCurrentMission'
      ]),
      hasSelectedMission () {
        return !!Object.keys(this.getCurrentMission).length
      }
    }
  }
</script>

<style lang="scss" scoped>
.missions-list .missions-list-content {
  flex-basis: 100%;
  width: 100%;
  border-right: 1px solid $gray;
}
@media (min-width: 770px) {
  .missions-list .missions-list-content {
    width: 420px;
    flex-basis: 420px;
  }
}
</style>
