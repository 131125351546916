<template>
  <div
    class="proof-of-delivery-upload tw-flex-1 tw-h-full tw-flex tw-flex-col tw-justify-between tw-items-center"
  >
    <ctk-dropzone
      ref="CtkDropzone"
      :url="urlDropzone"
      file-name-in-payload="proof_of_delivery"
      @file-upload-success="sended"
      @file-upload-error="error"
    />

    <div class="tw-w-full tw-flex tw-justify-between flex-fixed">
      <div
        v-if="errorMessage"
        class="tw-text-red-500 tw-flex tw-items-center tw-px-2 tw-text-right"
        v-text="errorMessage"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { EventBus } from '@/services/EventBus'
  import Config from '@/services/Config'

  import { env } from '@/../utils/env'

  import CtkDropzone from '@/components/CtkDropzone/index.vue'

  /**
   * @module component - ProofOfDeliveryUpload
   */
  export default {
    name: 'ProofOfDeliveryUpload',
    components: {
      CtkDropzone
    },
    data () {
      return {
        /** @type {any} */
        errorMessage: null
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMission',
        'getCurrentMissionUuid'
      ]),
      ...mapGetters('auth', [
        'getCid'
      ]),
      /**
       * @function urlDropzone
       */
      urlDropzone () {
        const files = this.getCurrentMission.proof_of_delivery.files.length > 0
        const ROOT_URL = `${Config.get(`api.base.${env}`)}v2/companies/${this.getCid}/missions/${this.getCurrentMissionUuid}`

        return files
          ? `${ROOT_URL}/replace-proof-of-delivery`
          : `${ROOT_URL}/proof-of-delivery`
      }
    },
    methods: {
      ...mapActions('missions', [
        'updateProofOfDelivery'
      ]),
      /**
       * @function sended
       */
      sended () {
        EventBus.$emit('launch-final-step')
        this.updateProofOfDelivery({
          value: {
            uuid: this.getCurrentMissionUuid,
            status: 'available'
          }
        })
      },
      /**
       * @function error
       * @param {any} err - The error object nor a error string
       */
      error (err) {
        if (err) {
          if (err.error) {
            if (err.error.code === 403) {
              this.$toasted.error(this.$t('not_allowed_error'), {
                duration: 10000,
                position: 'bottom-right',
                action: {
                  text: this.$t('close'),
                  onClick: (e, toast) => {
                    toast.goAway(0)
                  }
                }
              })
            } else {
              this.errorMessage = err.error.title || err.error.message
            }
          } else {
            this.errorMessage = this.$t('an_error_has_occurred')
          }
        } else {
          this.errorMessage = null
        }
      }
    }
  }
</script>
